import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';
import Spinner from './Components/Spinner';


function App() {
  const referenceImageRef = useRef();
  const [result, setResult] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSelectImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true); // Start loading
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewImage(fileReader.result);
        setUploadedImage(fileReader.result);
        setLoading(false); // End loading
      };
      fileReader.onerror = (error) => {
        console.error("Error reading file:", error);
        setLoading(false); // End loading in case of error
      };
      fileReader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchImageUrls = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch('https://facerecognition.5startek.tech/faceRecognition/api/v1/image/fc_img_list');
        const data = await response.json();
       
        const imgUrls = data.data;
       
        setImageUrls(imgUrls);
      } catch (error) {
        console.error("Failed to fetch image URLs:", error);
      }
      setLoading(false); // End loading
    };

    fetchImageUrls();
  }, []);

  useEffect(() => {
    const loadModelsAndCompareFaces = async () => {
      setLoading(true); // Start loading
      await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
      await faceapi.nets.faceExpressionNet.loadFromUri('/models');

      if (!referenceImageRef.current || imageUrls.length === 0) {
        setLoading(false); // End loading early if preconditions fail
        return;
      }

      const referenceImage = referenceImageRef.current;
      const referenceDetections = await faceapi.detectAllFaces(referenceImage, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceDescriptors();

      if (!referenceDetections || referenceDetections.length === 0) {
        console.log("No faces detected in reference image.");
        setLoading(false); // End loading if no faces detected
        return;
      }

      var results = [];
      for (let element of imageUrls) {
        try {
          const img = await faceapi.fetchImage(element);
          var detections = await faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptors();

          if (detections && detections.length !== 0) {
            const matches = referenceDetections.map(referenceFace => {
              return detections.some(targetFace => {
                const distance = faceapi.euclideanDistance(referenceFace.descriptor, targetFace.descriptor);
                return distance < 0.6; // similarity threshold
              });
            });
            if(matches.includes(true)) {
              results.push(element);
            }
          }
        } catch (error) {
          console.log("Detection error:", error);
        }
      }

      setResult(results.filter(url => url !== null));
      setLoading(false); // End loading
    };

    if (uploadedImage) {
      loadModelsAndCompareFaces();
    }
  }, [uploadedImage, imageUrls]);

  return (
    <>
      <div className='container'>
        <form name='imageUpload' encType='multipart/form-data'>
          <div className="col-md-4 mt-5">
            <h4><label className='form-label ms-2'>Select Image:</label></h4>
            <input type="file" className='form-control ms-2 mb-2' name='image' onChange={handleSelectImage} />
          </div>
          {previewImage && <img src={previewImage} className='m-2' alt="preview" width="150px" />}
          {uploadedImage && (
            <img
              ref={referenceImageRef}
              src={uploadedImage}
              alt="uploaded"
              width="150px"
              className='d-none'
            />
          )}
        </form>
        {loading && <Spinner />}
        
        <div className='row mt-2 ms-1'>
          {!loading && <h4><label className='form-label'>Similar Images</label></h4>}
          {!loading && result.map((imageUrl, index) => (
            <div key={index} className="gallery col-md-2 mt-2">
              <img src={imageUrl} alt={`Matched ${index + 1}`} style={{maxHeight:'200px',minHeight:'200px',width:'150px'}} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default App;
